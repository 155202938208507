import { Link } from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import MenuIcon from '@material-ui/icons/Menu';
import IconButton from '@material-ui/core/IconButton';
import NotificationsIcon from '@material-ui/icons/Notifications';
import AccountCircle from '@material-ui/icons/AccountCircle';
import Button from '@material-ui/core/Button';

import React, { Component, Fragment } from 'react'
import { inlineStyles } from '../helpers/global';
import { Box, TextField, MenuItem, InputBase, Icon, Badge, Menu, Tooltip } from '@material-ui/core';
import MenuBarItems from '../components/MenuBarItems';

export class TopMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      leftDrawerOpen: true,
    };
  }
  handleLeftDrawer = () => {
    this.setState({ leftDrawerOpen: !this.state.leftDrawerOpen });
  }
  render() {
    const { selectMenu, setSearchVal, setSearchTable, startSearch, clearSearch, handleProfileMenuOpen, handleMenuClose, logout } = this.props.parentComponent;
    const { userdata, searchTable, searchTableVal, isLoading, menuId, anchorEl } = this.props.parentComponent.state;
    return (
      <Fragment>          
        <CssBaseline />
        <AppBar position="fixed">
          <Toolbar variant="dense">
          <div style={{flex: '0 0 auto',paddingTop: 5}}>
          <svg width="60px" xmlns="http://www.w3.org/2000/svg" viewBox="0 166.3 900 496.5">
            <path fill="#42BF47" d="M75.5,418.9c34.4,0,54.7,25.2,54.7,53c0,20.2-9.3,35.5-29,44.8c17.5,9.3,22.4,32.3,45.9,86.4h-45.9
                c-18-39.4-24.6-74.9-47-74.9h-12v74.9H0V418.9H75.5z M42.1,494.4h22.4c11.5,0,23-5.5,23-21.3c0-13.7-8.2-20.8-24.1-20.8H42.1V494.4
                L42.1,494.4z"></path>
            <path fill="#42BF47" d="M290.9,603.2H167.3V418.9h121.9v33.9h-80.4v38.8h76.5v33.9h-76.5v44.3h82V603.2L290.9,603.2z"></path>
            <path fill="#42BF47" d="M434.7,594.5c-9.8,6.6-24.1,11.5-33.4,11.5c-12,0-17.5-3.8-19.7-14.2c-13.7,10.4-25.2,14.2-37.2,14.2
                c-18,0-31.7-14.8-31.7-30.6c0-29.5,19.7-35.5,67.3-51.9c0.5-11.5-4.4-20.8-19.7-20.8c-14.2,0-30.6,8.2-42.1,20.8v-31.7
                c14.8-9.8,31.2-15.3,49.2-15.3c28.4,0,48.1,10.4,48.1,37.7v56.9c0,6,2.2,8.7,6,8.7c2.2,0,4.9-0.5,13.7-7.1L434.7,594.5L434.7,594.5z
                M380,543.1c-21.3,8.2-31.2,13.7-31.2,26.2c0,7.1,4.9,13.1,11.5,13.1s13.7-3.3,19.7-9.3V543.1z"></path>
            <path fill="#42BF47" d="M486.1,603.2h-35.5V418.9h35.5V603.2z"></path>
            <path fill="#42BF47" d="M554.4,438.1c0,12-8.7,21.3-19.7,21.3s-19.7-9.8-19.7-21.3c0-11.5,8.7-21.3,19.7-21.3S554.4,426,554.4,438.1
                z M552.8,603.2h-35.5V478.5h35.5V603.2z"></path>
            <path fill="#42BF47" d="M664.3,505.3h-41v51.4c0,12,6.6,20.2,17.5,20.2c7.7,0,14.2-1.6,24.1-7.1v28.4c-12.6,5.5-21.9,7.7-32.8,7.7
                c-23.5,0-43.7-12-43.7-46.5v-54.1h-16.4v-7.7l51.9-64v44.8h41L664.3,505.3L664.3,505.3z"></path>
            <path fill="#42BF47" d="M717.4,662.8h-37.7L713,583l-44.3-104.4H707l24.1,64l25.7-64h37.2L717.4,662.8z"></path>
            <path fill="#3568E2" d="M482.3,166.3h78.2c0,0,0,67.8,0,102.8c0-0.5,89.1-84.8,89.1-84.8L900,401.4v95.1L611.8,246.2L407.4,440.3
                h-91.9v-21.3h86.4l80.4-76.5V166.3z"></path>
            <g>
                <path fill="#3568E2" d="M862.3,505.9c0,14.8-12,27.3-27.3,27.3c-14.8,0-27.3-12-27.3-27.3c0-14.8,12-27.3,27.3-27.3
                    C850.2,478.5,862.3,491.1,862.3,505.9z M813.1,505.9c0,12,9.8,21.9,21.9,21.9s21.9-9.8,21.9-21.9S847,484,834.9,484
                    S813.1,493.8,813.1,505.9z M840.9,520.6l-2.2-4.9c-1.1-2.2-2.7-4.4-3.8-5.5c-1.1-1.1-2.2-1.6-4.9-1.6h-1.1v12h-6v-29h14.2
                    c6,0,9.3,3.3,9.3,8.2c0,4.4-2.7,7.7-7.7,8.2l0,0c2.2,1.1,3.3,1.6,6,6.6l3.3,6H840.9L840.9,520.6z M834.4,504.2
                    c3.8,0,5.5-2.2,5.5-3.8c0-2.7-2.2-3.8-6-3.8h-5.5v7.7H834.4L834.4,504.2z"></path>
            </g></svg>
            </div>
            <div style={{flex: '0 0 auto', paddingLeft: 10}}>                
              <Typography style={{fontSize:24, color: 'red'}}>
              {process.env.REACT_APP_NAME}         
              </Typography>
            </div>
            <div style={{flex: '0 0 auto', paddingLeft: 30}}>
            <MenuBarItems App={this.props.parentComponent} />
            </div>

            <div style={inlineStyles.spacer}></div>
            <div style={{flex: '0 0 auto'}}>                
                <Typography style={{ marginLeft: 6, fontSize: '80%', color: '#333333' }}>Hi {userdata.userdata.firstname}!</Typography>
            </div>
            <div style={{flex: '0 0 auto'}}>
                <Tooltip title="Logout">
                <Button                
                    style={{ minWidth: 30, width: 30}}        
                    onClick={logout}
                >
                    <i className="fas fa-sign-out-alt"></i>
                </Button>
                </Tooltip>
            </div>
          </Toolbar>
        </AppBar>       
        
        <main
          style={this.state.leftDrawerOpen === true ? inlineStyles.contentShift : inlineStyles.content}
        >
          <div style={inlineStyles.drawerHeader} />

          {this.props.content}

        </main>
      </Fragment>

    )
  }
}

export default TopMenu;