import React, { Fragment, Component } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import 'date-fns';
import { EnhancedTableToolbar, EnhancedTableHead } from '../../helpers/table_helper';
import { Global, inlineStyles } from '../../helpers/global';
import SnackerBar from '../../components/SnackerBar';
import Button from '@material-ui/core/Button';
import { Link, Grid, MenuItem, TableFooter, Checkbox } from '@material-ui/core';

const reportTitle = "Activities Report";

export default class Activities extends Component {
    constructor(props) {
        super(props);
        this.state = {
            url: process.env.REACT_APP_API_HOST + 'activitiesReport',
            assignedTo: '0',
            orderBy: '',
            count: 1,
            deleteDialogOpen: false,
            dialogAddEditOpen: false,
            dense: false,
            id: 0,
            isLoading: true,
            isDataLoading: false,
            isDialogDataLoading: false,
            notification: '',
            objName: '',
            order: 'desc',
            page: 0,
            pageTotal: 0,
            reportCode: 'report.todayEvents',
            rows: [],
            rowsPerPage: 20,
            searchFor: '',
            selected: [],
            selectedType: 0,
            selectedUser: 0,
            startDate: '',
            endDate: '',            
            snackBarMsg: '',
            snackBarMsgType: '',
            snackBarOpen: false,
            showClearSearchBtn: false,
            schema: [],
            Users: [],
            ContactTypes: [],
        };
    }
    openSnackBar = (snackBarMsg, snackBarMsgType, snackBarOpen) => {
        this.setState({ snackBarMsg: snackBarMsg, snackBarMsgType: snackBarMsgType, snackBarOpen: snackBarOpen });
    }
    setComponentState = (sState, vState) => {
        this.setState({ [sState]: vState });
    }
    handleTextfieldChange = (name, key) => e => {
        var values = { ...this.state.schema };
        values[key].value = e.target.value;
        this.setState({ values });
    }
    handleRequestSort = (event, property) => {
        var isDesc = this.state.order === 'desc';
        var order = isDesc || this.state.orderBy !== property ? 'asc' : 'desc';
        var values = {
            order: order,
            orderBy: property,
        };
        this.setComponentState('order', order);
        this.setComponentState('orderBy', property);
        Global.loadList(this, values);
    }
    setSearch = (e) => {
        var v = e.target.value;
        this.setState({ searchFor: v });
        if (v) {
            this.setComponentState('showClearSearchBtn', true);
        } else {
            this.setComponentState('showClearSearchBtn', false);
        }
    }

    handleChangePage = (event, newPage) => {
        this.setComponentState('page', newPage);
        Global.loadList(this, { page: newPage });
    }

    handleChangeRowsPerPage = (event) => {
        this.setComponentState('rowsPerPage', event.target.value);
        Global.loadList(this, { rowsPerPage: event.target.value });
    }

    handleChangeDense = (event) => {
        this.setComponentState('dense', event.target.checked);
    }

    handleDialogClose = () => {
        this.setDeleteDialogOpen(false);
    }
    confirmDeleteRow(id, name) {
        var states = this.state;
        states.id = id;
        states.objName = name;
        states.deleteDialogOpen = true;
        this.setState({ states });
    }
    confirmDeletion = (id) => {
        Global.deleteRow(this, id);
    }
    setDeleteDialogOpen = (v) => {
        if (!v) {
            Global.loadList(this, { isDataLoading: false });
        }
        this.setState({ deleteDialogOpen: v });
    }
    setEditDialogOpen = (v) => {
        this.setState({ dialogAddEditOpen: v });
        if (!v) {
            var formValues = this.state.schema;
            var values = { ...this.state.schema };
            formValues.forEach((row, key) => {
                values[key].error = '';
                values[key].value = '';
                this.setState({ values });
            })
            Global.loadList(this, { isDataLoading: false });
        }
    }
    showDetails = (id) => {
        let oId = { idFor: 'selectedID', value: id };
        this.props.parentComponent.selectMenu('activity.details', oId, {});
    }
    editRow = (id) => {
        // let oId = { idFor: 'selectedID', value: id };
        // this.props.parentComponent.selectMenu(editPage, oId, {});
        /* Use this 2 lines below instead of you want to edit in a dialog box */
        Global.getOneRow(this, id);
        this.setEditDialogOpen(true);
    }

    addNew = () => {
        // this.editRow(0);
        /* Use the codes below if you want popup for editing */
        var values = { ...this.state.schema };
        values.id = 0;
        this.setState({ values });
        this.setEditDialogOpen(true);
    }
    setUser = (e) => {
        this.setState({ selectedUser: e.target.value });
    }
    setContactType = (e) => {
        this.setState({ selectedType: e.target.value });
    }
    setStartDate = (e) => {
        this.setState({ startDate: e.target.value });
    }
    setEndDate = (e) => {
        this.setState({ endDate: e.target.value });
    }
    
    actionButtons = () => {
        var formValues = this.state.schema;
        var Selection = [];
        var ownerSelection = [];

        formValues.forEach((row) => {
            if (row.name === 'report') {
                Selection = row.selection;
            } else if (row.name === 'ownerid') {
                ownerSelection = row.selection;
            }
        });
        return (
            <Fragment>
                <TextField
                    select
                    variant="outlined"
                    margin="dense"
                    value={this.props.reportCode}
                    style={{ width: 200, marginRight: 5 }}
                    onChange={this.changeReport}
                    disabled={this.state.isDialogDataLoading}
                >
                    {Selection.map((option) => {
                        return (
                            <MenuItem key={option.key} value={option.key}>
                                {option.value}
                            </MenuItem>
                        )
                    })
                    }
                </TextField>

                <TextField
                    select
                    variant="outlined"
                    margin="dense"
                    value={this.state.assignedTo}
                    style={{ width: 150 }}
                    onChange={this.changeOwner}
                    disabled={this.state.isDialogDataLoading}
                >
                    {ownerSelection.map((option) => {
                        return (
                            <MenuItem key={option.key} value={option.key}>
                                {option.value}
                            </MenuItem>
                        )
                    })
                    }
                </TextField>
                
            </Fragment>
        )
    }

    changeReport = (e) => {
        this.props.parentComponent.setState({ selectedMenu: e.target.value });            
    }
    afterChangeReport = () => {        
        Global.loadList(this, { isDataLoading: false });
    }
    changeOwner = (e) => {
        this.setState({ assignedTo: e.target.value });    
        var values = [];
        values.push({ name: 'assignedTo', value: e.target.value, required: false });
        values.push({ name: 'reportCode', value: this.props.reportCode, required: false });
        let url = process.env.REACT_APP_API_HOST + 'activitiesReport';
        Global.SaveDialogFormData(this, url, values, '', this.afterChangeReport, 'Loaded activities for selected owner');
    }
    
    componentDidUpdate() {        
        if (this.state.reportCode!=this.props.reportCode) {
            this.setState({ ...this.state, reportCode: this.props.reportCode});
            this.afterChangeReport();
        }
    }
    componentDidMount() {
        if (this.state.isLoading && !this.state.isDataLoading) {
            Global.getSchema(this, false);
            var date = new Date();
            this.setState({ ...this.state, startDate: new Date(date.getFullYear(), date.getMonth() - 2, 1).toISOString().split('T')[0], endDate: new Date().toISOString().split('T')[0], reportCode: this.props.reportCode });
        }
    }
    render() {
        var headRows = [];
        var formValues = this.state.schema;

        formValues.forEach((row) => {
            headRows.push({ id: row.name, label: row.label, numeric: row.numeric, showInList: row.showInList });
        });

        var permissions = this.props.parentComponent.state.permissions;

        return (
            <Fragment>
                <Paper style={inlineStyles.paper}>
                    {
                        this.state.notification &&
                        <Typography paragraph color="error" align="center" style={{ 'padding': 20 }}>
                            {this.state.notification}
                        </Typography>
                    }

                    <Fragment>
                        <EnhancedTableToolbar
                            numSelected={this.state.selected ? this.state.selected.length : 0}
                            toolbarTitle={reportTitle}
                            showSearchBox={false}
                            actionButtons={this.actionButtons}
                            t={this}
                            Global={Global}
                        />
                        {!this.state.isDataLoading && !this.state.isDialogDataLoading &&
                            <Fragment>
                                <div style={inlineStyles.tableWrapper}>
                                    <Table
                                        style={inlineStyles.table}
                                        aria-labelledby="tableTitle"
                                        size={this.state.dense ? 'medium' : 'small'}
                                    >
                                        <EnhancedTableHead
                                            numSelected={this.state.selected ? this.state.selected.length : 0}
                                            order={this.state.order}
                                            orderBy={this.state.orderBy}
                                            onSelectAllClick={false}
                                            onRequestSort={this.handleRequestSort}
                                            headRows={headRows}
                                        />
                                        <TableBody>
                                            {this.state.rows && this.state.rows.length > 0 && this.state.rows.map((row, index) => {
                                                const labelId = `table-row-${index}`;
                                                return (
                                                    <TableRow
                                                        hover
                                                        key={index}
                                                    >
                                                        <TableCell className="listRowNum" id={labelId} scope="row" padding="none" align="center">
                                                            {index + 1 + (this.state.page * this.state.rowsPerPage)}
                                                        </TableCell>
                                                        
                                                        {formValues.filter(col => col.showInList).map((col, colindex) => {

                                                            const colval = colindex
                                                                ? row[col.name]
                                                                : (<Link to="#" onClick={() => this.showDetails(row.crmid)} aria-label="Show contact details">{row[col.name]}</Link>);
                                                            return (
                                                                <TableCell key={colindex} align={col.numeric ? 'right' : (col.align ? col.align : 'left')}>
                                                                    {row[col.name]}
                                                                </TableCell>
                                                            );

                                                        })
                                                        }


                                                    </TableRow>
                                                );
                                            })
                                            }
                                            {this.state.rows && !this.state.rows.length &&
                                                <TableRow><TableCell align="center" colSpan={8}>{"No result"}</TableCell></TableRow>
                                            }
                                        </TableBody>

                                    </Table>
                                </div>
                                {this.state.pageTotal > 1 &&
                                    <TablePagination
                                        rowsPerPageOptions={[10, 20, 50, 100]}
                                        component="div"
                                        count={this.state.count}
                                        rowsPerPage={this.state.rowsPerPage}
                                        page={this.state.page}
                                        backIconButtonProps={{
                                            'aria-label': 'Previous Page',
                                        }}
                                        nextIconButtonProps={{
                                            'aria-label': 'Next Page',
                                        }}
                                        onChangePage={this.handleChangePage}
                                        onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                    />
                                }
                            </Fragment>
                        }
                    </Fragment>
                    {
                        (this.state.isDataLoading || this.state.isDialogDataLoading) &&
                        <Fragment>
                            <div style={{ padding: 50, textAlign: 'center' }}>
                                {this.props.parentComponent.loadingCircular()}
                            </div>
                        </Fragment>
                    }
                </Paper>

                <SnackerBar msg={this.state.snackBarMsg} msgType={this.state.snackBarMsgType} opened={this.state.snackBarOpen} t={this} />
                <div style={{ padding: 25 }}></div>
            </Fragment>
        );
    }
}
