import React, { Component, Fragment } from "react";
import { Paper, Grid, TextField, Button, MenuItem, Typography } from "@material-ui/core";
import { inlineStyles, Global } from "../../helpers/global";
import "../../css/calendar.css";
import "@fullcalendar/daygrid/main.css";
import "@fullcalendar/timegrid/main.css";
import "@fullcalendar/list/main.css";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextAutoSearch from "../../components/TextAutoSearch";
import MaterialUIPickers from "../../components/MaterialUIPickers";
import SnackerBar from '../../components/SnackerBar';
import FullCalendar from "./calendar";

const url = 'events';
function roundTimeHalfHour(time) {
  var timeToReturn = new Date(time);

  timeToReturn.setMilliseconds(Math.round(time.getMilliseconds() / 1000) * 1000);
  timeToReturn.setSeconds(Math.round(timeToReturn.getSeconds() / 60) * 60);
  timeToReturn.setMinutes(Math.round(timeToReturn.getMinutes() / 30) * 30);
  return timeToReturn;
}

export default class CRMCalendar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      url: process.env.REACT_APP_API_HOST + url,
      calendar: null,
      id: 0,
      events: {},
      dialogAddEditOpen: false,
      deleteDialogOpen: false,
      eventContactName: '',
      schema: [],
      isDataLoading: false,
      isDialogDataLoading: false,
      isLoading: true,
      order: '',
      orderBy: '',
      rowsPerPage: '',
      page: '',
      searchFor: '',
      newDateStart: new Date().toJSON(),
      newTitle: '',
      reloadKey: 0,
      selectedUserId: 0,
      snackBarMsg: '',
      snackBarMsgType: '',
      snackBarOpen: false,
    };
    this.calendarRef = React.createRef();
  }

  editEvent = (row) => {
    this.addEvent(row);
  }
  addEvent = (row) => {
    var schema = this.state.schema;
    var values = { ...this.state.schema };
    var activitytype = 'Call Out';
    var owner = this.props.parentComponent.state.userdata.userdata.id;
    var contactid = 0;
    var description = '';
    var subject = '';
    var date_start = new Date().toJSON();
    var ddate = new Date();
    var contactname = '';

    if (typeof row !== 'undefined' && row.hasOwnProperty('event')) {
      activitytype = row.event.extendedProps.activitytype;
      ddate = new Date(row.event.start);
      date_start = ddate.toJSON();
      subject = row.event.title;
      contactid = row.event.extendedProps.contactid;
      owner = row.event.extendedProps.owner;
      description = row.event.extendedProps.description;
      contactname = row.event.extendedProps.contact;
      this.setState({ id: row.event.id });
    }

    var timeStart = roundTimeHalfHour(ddate);
    timeStart = timeStart.toTimeString().substring(0, 5);

    schema.find((o, i) => {
      if (o.name === 'activitytype') {
        values[i].value = activitytype;
        return true;
      }
    });
    schema.find((o, i) => {
      if (o.name === 'smownerid') {
        values[i].value = owner;
        return true;
      }
    });
    schema.find((o, i) => {
      if (o.name === 'contactid') {
        values[i].value = contactid;
        return true;
      }
    });
    schema.find((o, i) => {
      if (o.name === 'description') {
        values[i].value = description;
        return true;
      }
    });
    schema.find((o, i) => {
      if (o.name === 'subject') {
        values[i].value = subject;
        return true;
      }
    });
    schema.find((o, i) => {
      if (o.name === 'date_start') {
        values[i].value = date_start;
        return true;
      }
    });
    schema.find((o, i) => {
      if (o.name === 'time_start') {
        values[i].value = timeStart;
        return true;
      }
    });
    this.setState({ ...this.state, values, dialogAddEditOpen: true, eventContactName: contactname });
  }
  openSnackBar = (snackBarMsg, snackBarMsgType, snackBarOpen) => {
    this.setState({ snackBarMsg: snackBarMsg, snackBarMsgType: snackBarMsgType, snackBarOpen: snackBarOpen });
  }
  closeEvent = () => {
    this.setState({ dialogAddEditOpen: false });
  }
  updateEvents = () => {
    Global.getDataRows(this, process.env.REACT_APP_API_HOST + 'events', 'events', 'selectEventsByOwner', [{ name: 'owner', value: this.state.selectedUserId }]);
  }
  handleNewEventChange = (name) => event => {
    if (name === 'subject') {
      this.setState({ newTitle: event.target.value });
    }
    Global.setStateValue(this, name, event.target.value);
  };
  handleChangeDate = (name, date) => {
    this.setState({ newDateStart: date });
    Global.setStateValue(this, name, date);
  };
  changeCalUser = () => event => {
    this.setState({ ...this.state, selectedUserId: event.target.value });
    Global.getDataRows(this, process.env.REACT_APP_API_HOST + 'events', 'events', 'selectEventsByOwner', [{ name: 'owner', value: event.target.value }]);
  }
  updateRowObj = (tabname, suggestion) => {
    if (tabname === 'contact') {
      this.setState({ eventContactName: suggestion.label });
      Global.setStateValue(this, 'contactid', suggestion.id);
    }
  }
  confirmDeleteRow = () => {
    this.setDeleteDialogOpen(true);
  }
  confirmDeletion = () => {
    Global.deleteRow(this, this.state.id);
  }
  setDeleteDialogOpen = (v) => {
    this.setState({ deleteDialogOpen: v });
    if (!v) {
      this.closeEvent();
      this.updateEvents();
    }
  }
  componentDidMount() {
    if (this.state.isLoading && !this.state.isDataLoading) {
      Global.getSchema(this, false);
      var userid = this.props.parentComponent.state.userdata.userdata.id;
      this.setState({ ...this.state, selectedUserId: userid, isLoading: false });
      Global.getDataRows(this, process.env.REACT_APP_API_HOST + 'events', 'events', 'selectEventsByOwner', [{ name: 'owner', value: userid }]);
    }

  }

  setComponentState = (sState, vState) => {
    this.setState({ [sState]: vState });
  }

  render() {
    var formValues = this.state.schema;
    var calTitle = 'Calendar';
    return (
      <Fragment>
        <Paper style={{ padding: 10, marginBottom: 25 }}>
          <div style={{ display: 'flex', marginBottom: 20, marginTop: 10 }}>
            <div style={{ flex: '0 1 auto' }}>
              <Typography variant="h6" className='reportTitleGreen'>
                {calTitle}
              </Typography>
            </div>
            <div style={{ flex: '1 1 auto' }}></div>
            <div style={{ flex: '0 1 auto', paddingRight: 5 }}><h4 style={{ margin: 0, padding: 5 }}>Calendar User:</h4> </div>
            <div style={{ flex: '0 1 auto', width: 237 }}>
              {formValues.filter(row => row.name === 'smownerid').map((row, index) => {
                return (
                  <TextField
                    key={index}
                    select
                    variant="outlined"
                    margin="dense"
                    value={this.state.selectedUserId}
                    onChange={this.changeCalUser()}
                  >
                    {row.selection.length > 0 && row.selection.map(option => (
                      <MenuItem key={option.key} value={option.key}>
                        {option.value}
                      </MenuItem>
                    ))}
                  </TextField>
                );
              })
              }
            </div>
          </div>

          <div>
            {this.state.isDataLoading
              ?
              this.props.parentComponent.loadingCircular()
              :
              <FullCalendar App={this.parentComponent} key={this.state.reloadKey} parentComponent={this} Events={this.state.events} />
            }
          </div>
        </Paper>
        <Dialog
          open={this.state.dialogAddEditOpen}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth='md'
          fullWidth={true}
        >
          <DialogTitle id="alert-dialog-title">Event</DialogTitle>
          <DialogContent dividers>
            <DialogContentText id="alert-dialog-description"></DialogContentText>
            {this.state.isDialogDataLoading ?
              this.props.parentComponent.loadingCircular()
              :
              <div style={{ padding: 15 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    {formValues.filter(row => row.name === 'subject').map((row, index) => {
                      return (
                        <Fragment key={index}>
                          <TextField
                            variant="outlined"
                            autoFocus={true}
                            margin="dense"
                            error={row.error !== ''}
                            value={row.value}
                            name={row.name}
                            label={row.error ? row.error : row.label}
                            onChange={this.handleNewEventChange(row.name)}
                            required={row.required}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </Fragment>
                      );
                    })
                    }
                  </Grid>
                  <Grid item xs={12}>
                    {formValues.filter(row => row.name === 'description').map((row, index) => {
                      return (
                        <Fragment key={index}>
                          <TextField
                            multiline
                            rows={3}
                            margin="dense"
                            variant="outlined"
                            autoFocus={false}
                            error={row.error !== ''}
                            value={row.value}
                            name={row.name}
                            label={row.error ? row.error : row.label}
                            onChange={this.handleNewEventChange(row.name)}
                            required={row.required}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </Fragment>
                      );
                    })
                    }
                  </Grid>
                  <Grid item xs={4}>
                    {formValues.filter(row => row.name === 'date_start').map((row, index) => {
                      return (
                        <Fragment key={index}>
                          <MaterialUIPickers parentComponent={this} dateValue={row.value} />
                        </Fragment>
                      );
                    })
                    }
                  </Grid>
                  <Grid item xs={4}>
                    {formValues.filter(row => row.name === 'time_start').map((row, index) => {
                      return (
                        <Fragment key={index}>
                          <TextField
                            select
                            variant="outlined"
                            margin="dense"
                            autoFocus={false}
                            error={row.error !== ''}
                            value={row.value}
                            name={row.name}
                            label={row.error ? row.error : row.label}
                            onChange={this.handleNewEventChange(row.name)}
                            required={row.required}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          >
                            {row.selection.length > 0 && row.selection.map(option => (
                              <MenuItem key={option.key} value={option.key}>
                                {option.value}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Fragment>
                      );
                    })
                    }
                  </Grid>

                  <Grid item xs={4}>
                    {formValues.filter(row => row.name === 'activitytype').map((row, index) => {
                      return (
                        <Fragment key={index}>
                          <TextField
                            select
                            variant="outlined"
                            margin="dense"
                            autoFocus={false}
                            error={row.error !== ''}
                            value={row.value}
                            name={row.name}
                            label={row.error ? row.error : row.label}
                            onChange={this.handleNewEventChange(row.name)}
                            required={row.required}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          >
                            {row.selection.length > 0 && row.selection.map(option => (
                              <MenuItem key={option.key} value={option.key}>
                                {option.value}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Fragment>
                      );
                    })
                    }
                  </Grid>
                  <Grid item xs={6} style={{ zIndex: 200 }}>
                    {formValues.filter(row => row.name === 'contactid').map((row, index) => {
                      return (
                        <TextAutoSearch
                          key={index}
                          parentComponent={this.props.parentComponent}
                          updateRowObj={this.updateRowObj}
                          margin="dense"
                          textValue={this.state.eventContactName}
                          tabname='contact'
                          t={this} />
                      );
                    })
                    }
                  </Grid>
                  <Grid item xs={6}>
                    {formValues.filter(row => row.name === 'smownerid').map((row, index) => {
                      return (
                        <TextField
                          select
                          key={index}
                          variant="outlined"
                          margin="dense"
                          autoFocus={false}
                          error={row.error !== ''}
                          value={row.value}
                          name={row.name}
                          label={row.error ? row.error : row.label}
                          onChange={this.handleNewEventChange(row.name)}
                          required={row.required}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        >
                          {row.selection.length > 0 && row.selection.map(option => (
                            <MenuItem key={option.key} value={option.key}>
                              {option.value}
                            </MenuItem>
                          ))}
                        </TextField>
                      );
                    })
                    }
                  </Grid>

                </Grid>
              </div>
            }
          </DialogContent>
          <DialogActions>
            <Button onClick={Global.handleSaveForm(this, this.updateEvents)} variant='contained' color="primary" size="small" style={inlineStyles.button}>
              Save
            </Button>
            <Button onClick={this.closeEvent} variant='contained' color="secondary" size="small" style={inlineStyles.button}>
              Cancel
            </Button>
            {this.state.id > 0 &&
              <Button onClick={this.confirmDeleteRow} variant='contained' color="secondary" size="small" style={inlineStyles.button}>
                Delete
            </Button>
            }
          </DialogActions>
        </Dialog>
        <Dialog
          open={this.state.deleteDialogOpen}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Confirm"}</DialogTitle>
          <DialogContent dividers>
            <DialogContentText id="alert-dialog-description"></DialogContentText>
            <div style={{ padding: 15 }}>
              Do you want to continue deleting selected event?
                        </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.setDeleteDialogOpen(false)} variant='contained' color="secondary" size="small" style={inlineStyles.button}>
              No
                    </Button>
            <Button onClick={() => this.confirmDeletion(this.state.id)} variant='contained' color="primary" size="small" style={inlineStyles.button} autoFocus>
              Yes
                    </Button>
          </DialogActions>
        </Dialog>

        <SnackerBar msg={this.state.snackBarMsg} msgType={this.state.snackBarMsgType} opened={this.state.snackBarOpen} t={this} />
      </Fragment>
    );
  }
}