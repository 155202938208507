import React, { Fragment, Component } from 'react';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import SnackerBar from '../../components/SnackerBar';
import { Global, inlineStyles } from '../../helpers/global';
import { EnhancedTableToolbar } from '../../helpers/table_helper';
import { Box, Grid, MenuItem } from '@material-ui/core';
import 'date-fns';
import TextAutoSearch from '../../components/TextAutoSearch';

const EntityName = 'Office';
const url = 'offices';

export default class EditOffice extends Component {
    constructor(props) {
        super(props);
        this.state = {
            url: process.env.REACT_APP_API_HOST + url,
            id: this.props.parentComponent.state.selectedID,
            redirectTo: 'office.details',
            notification: '',
            isLoading: true,
            isDataLoading: false,
            selectedID: 0,
            IdFromTextAutoSearch: 0,
            roles: [],
            objName: '',
            schema: [],
            snackBarMsg: '',
            snackBarMsgType: '',
            snackBarOpen: false,
            isEdit: true
        };
    }
    openSnackBar = (snackBarMsg, snackBarMsgType, snackBarOpen) => {
        this.setState({ snackBarMsg: snackBarMsg, snackBarMsgType: snackBarMsgType, snackBarOpen: snackBarOpen });
    }
    handleTextfieldChange = (name) => event => {
        Global.setStateValue(this, name, event.target.value);
    };
    
    setComponentState = (sState, vState) => {
        this.setState({ [sState]: vState });
    }

    actionButtons = () => {
        return (
            <Box style={inlineStyles.toolbarActionBox}>
                <Button onClick={Global.handleSaveForm(this)} style={inlineStyles.button} color="primary" variant='contained' size="small">
                    <Icon className="fas fa-save" style={inlineStyles.smallIcon}></Icon>Save
                        </Button>

                <Button onClick={this.backToParent} style={inlineStyles.buttonOrange} color="secondary" variant='contained' size="small">
                    <Icon className={"fas fa-arrow-circle-left"} style={inlineStyles.smallIcon}></Icon>Back to Offices
                </Button>
            </Box>
        )
    }
    backToParent = () => {
        this.props.parentComponent.selectMenu('offices', 0);
    }
    updateRowObj = (tabname,suggestion) => {
        if (tabname==='offices') {
            Global.setStateValue(this, 'accountid', suggestion.id);
        }
    }
    componentDidMount() {
        if (this.state.isLoading && !this.state.isDataLoading) {
            //Global.getDataRows(this, process.env.REACT_APP_ROLES_URL, 'roles');
            Global.getSchema(this, true); /* if second parameter is true, the record to edit will be loaded (getOneRow()) */
        }
    }
    render() {
        const reportTitle = this.state.id ? 'Edit office' : 'New office';
        var values = this.state.schema;

        return (
            <Fragment>
                <Paper style={inlineStyles.paper}>
                    {
                        this.state.notification &&
                        <Typography paragraph color="error" align="center" style={{ 'padding': 20 }}>
                            {this.state.notification}
                        </Typography>
                    }
                    {
                        this.state.isDataLoading &&
                        <Fragment>
                            <div style={{ 'padding': 50, 'textAlign': 'center' }}>
                                <CircularProgress />
                            </div>
                        </Fragment>
                    }
                    {!this.state.isDataLoading &&
                        <Fragment>
                            <EnhancedTableToolbar
                                numSelected={0}
                                color="secondary"
                                toolbarTitle={reportTitle}
                                actionButtons={this.actionButtons}
                            />
                            <form autoComplete="off" style={inlineStyles.editform}>
                                <table>
                                    <tbody>
                                        <tr>
                                            <td className="tableContainer">
                                                <table border={0}>
                                                    <tbody>
                                                        {values.filter((row) => (row.col === 1)).map((row, index) => {
                                                            return (
                                                                <tr key={index}>
                                                                    <td className="tdLabel">{row.label}: </td>
                                                                    <td className="tdEdit">
                                                                    {row.type !== 'noedit' ?
                                                                            (
                                                                                row.type === 'dropdown' ?
                                                                                    <TextField
                                                                                        select
                                                                                        error={row.error ? true : false}
                                                                                        variant="outlined"
                                                                                        margin="dense"
                                                                                        value={row.value}
                                                                                        onChange={this.handleTextfieldChange(row.name)}
                                                                                        required={row.required}                                                                                        
                                                                                        name={row.name}
                                                                                    >
                                                                                        {row.selection.length>0 && row.selection.map(option => (
                                                                                            <MenuItem key={option.key} value={option.key}>
                                                                                                {option.value}
                                                                                            </MenuItem>                                                                                            
                                                                                        ))}
                                                                                    </TextField>
                                                                                    :
                                                                                    (
                                                                                    row.type === 'searchbox' ?
                                                                                    <TextAutoSearch parentComponent={this.props.parentComponent} updateRowObj={this.updateRowObj} tabname='offices' t={this} />
                                                                                    :
                                                                                    <TextField
                                                                                        error={row.error ? true : false}
                                                                                        variant="outlined"
                                                                                        margin="dense"
                                                                                        name={row.name}
                                                                                        required={row.required}
                                                                                        onChange={this.handleTextfieldChange(row.name)}
                                                                                        value={row.value} />
                                                                                    )
                                                                            )
                                                                            :
                                                                            ( 
                                                                                row.value
                                                                            )
                                                                        }
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })
                                                        }
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                
                            </form>
                        </Fragment>
                    }

                </Paper>
                <SnackerBar msg={this.state.snackBarMsg} msgType={this.state.snackBarMsgType} opened={this.state.snackBarOpen} t={this} />
            </Fragment>
        );
    }
}

