import React, { Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { inlineStyles } from '../helpers/global';

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})(props => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles(theme => ({
  root: {
    '&:focus': {
      backgroundColor: theme.palette.primary.main,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

const reports = [
{ name: 'Today Activities', link: 'todayEvents' },
{ name: 'Yesterday Activities', link: 'yesterdayEvents' },
{ name: 'This Week Activities', link: 'thisWeekEvents' },
{ name: 'Last Week Activities', link: 'lastWeekEvents' },
{ name: 'This Month Activities', link: 'thisMonthEvents' },
{ name: 'Today Emails In', link: 'todayEmailsIn' },
{ name: 'Yesterday Emails In', link: 'yesterdayEmailsIn' },
{ name: 'Today Emails Out', link: 'todayEmailsOut' },
{ name: 'Yesterday Emails Out', link: 'yesterdayEmailsOut' },
{ name: 'Unlinked Emails', link: 'unlinked' },
{ name: 'Contacts without Offices', link: 'noAccounts' },
{ name: 'To be Assigned', link: 'tobeAssigned' },
];
// { name: 'Contacts by Offices', link: 'contactsByAccounts' },
export default function MenuBarItems(props) {
  const [anchorEl1, setAnchorEl1] = React.useState(null);
  const [anchorEl2, setAnchorEl2] = React.useState(null);

  function handleClick1(event) {
    setAnchorEl1(event.currentTarget);
  }

  function handleClose1() {
    setAnchorEl1(null);
  }
  function handleClick2(event) {
    setAnchorEl2(event.currentTarget);
  }
  function handleClose2() {
    setAnchorEl2(null);
  }
  var permissions = props.App.state.permissions;
  var profileId = props.App.state.userdata.userdata.id;

  return (
    <Fragment>
      {(permissions.Contacts.indexOf('f') !== -1 || permissions.Contacts.indexOf('v') !== -1) &&
        <Button onClick={() => props.App.selectMenu('calendar')}>Calendar</Button>
      }
      {(permissions.Contacts.indexOf('f') !== -1 || permissions.Contacts.indexOf('v') !== -1) &&
        <Fragment>
          <div style={inlineStyles.menuDivider}>|</div>
          <Button onClick={() => props.App.selectMenu('contacts')}>Contacts</Button>
        </Fragment>
      }

      {(permissions.Offices.indexOf('f') !== -1 || permissions.Offices.indexOf('v') !== -1) &&
        <Fragment>
          <div style={inlineStyles.menuDivider}>|</div>
          <Button onClick={() => props.App.selectMenu('offices')}>Offices</Button>
        </Fragment>
      }

      {(permissions.Archived.indexOf('f') !== -1 || permissions.Archived.indexOf('v') !== -1) &&
        <Fragment>
          <div style={inlineStyles.menuDivider}>|</div>
          <Button onClick={() => window.open("http://salescrm.restats.com")}>Active CRM</Button>
        </Fragment>
      }

    </Fragment>
  );
}