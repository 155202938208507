import React, { Component, Fragment } from "react";
import { Grid, TextField, Button, MenuItem, Typography } from "@material-ui/core";
import { inlineStyles, Global } from "../../helpers/global";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextAutoSearch from "../../components/TextAutoSearch";
import MaterialUIPickers from "../../components/MaterialUIPickers";
import moment from "moment";

function roundTimeHalfHour(time) {
  var timeToReturn = new Date(time);

  timeToReturn.setMilliseconds(Math.round(time.getMilliseconds() / 1000) * 1000);
  timeToReturn.setSeconds(Math.round(timeToReturn.getSeconds() / 60) * 60);
  timeToReturn.setMinutes(Math.round(timeToReturn.getMinutes() / 30) * 30);
  return timeToReturn;
}

export default class EditEvent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: this.props.parentComponent.state.event.id,
      eventContactName: '',
      dialogAddEditOpen: this.props.parentComponent.state.dialogEventEditOpen,
      isDialogDataLoading: this.props.parentComponent.state.isEventDataLoading,
      isLoading: true,
      newDateStart: new Date().toJSON(),
      reloadEvent: this.props.reloadEvent,
      newTitle: '',
      schema: [],
      rows: [],
      url: process.env.REACT_APP_API_HOST + 'events',
      selectedUserId: 0,
    };
  }

  editEvent = (event) => {
    this.addEvent(event);
  }

  addEvent = (event) => {
    var schema = this.state.schema;
    var values = { ...this.state.schema };
    var activitytype = 'Call Out';
    var owner = this.props.App.state.userdata.userdata.id;
    var contactid = 0;
    var description = '';
    var subject = '';
    var date_start = moment().format();  
    var ddate = new Date(date_start);
    var contactname = '';

    if (typeof event !== 'undefined' && event.hasOwnProperty('id')) {
      activitytype = event.activitytype;
      ddate = new Date(event.start);
      date_start = ddate.toJSON();
      subject = event.title;
      contactid = event.contactid;
      owner = event.owner;
      description = event.description;
      contactname = event.contact;
    } else if (typeof event !== 'undefined' && event.hasOwnProperty('contactid')) {
      contactid = event.contactid;
      if (event.hasOwnProperty('contact')) {
        contactname = event.contact;
      }
    }
    date_start = date_start.split('T')[0];
    var timeStart = roundTimeHalfHour(ddate);
    timeStart = timeStart.toTimeString().substring(0, 5);

    schema.find((o, i) => {
      if (o.name === 'activitytype') {
        values[i].value = activitytype;
        return true;
      }
    });
    schema.find((o, i) => {
      if (o.name === 'smownerid') {
        values[i].value = owner;
        return true;
      }
    });
    schema.find((o, i) => {
      if (o.name === 'contactid') {
        values[i].value = contactid;
        return true;
      }
    });
    schema.find((o, i) => {
      if (o.name === 'description') {
        values[i].value = description;
        return true;
      }
    });
    schema.find((o, i) => {
      if (o.name === 'subject') {
        values[i].value = subject;
        return true;
      }
    });
    schema.find((o, i) => {
      if (o.name === 'date_start') {
        values[i].value = date_start;
        return true;
      }
    });
    schema.find((o, i) => {
      if (o.name === 'time_start') {
        values[i].value = timeStart;
        return true;
      }
    });
    this.setState({ ...this.state, values, eventContactName: contactname, isLoading: false });
  }

  closeEvent = () => {
    this.props.parentComponent.setState({ dialogEventEditOpen: false });
  }

  handleNewEventChange = (name) => event => {
    if (name === 'subject') {
      this.setState({ newTitle: event.target.value });
    }
    Global.setStateValue(this, name, event.target.value);
  };
  handleChangeDate = (name, date) => {
    this.setState({ newDateStart: date });
    Global.setStateValue(this, name, date);
  };

  updateRowObj = (tabname, suggestion) => {
    if (tabname === 'contact') {
      this.setState({ eventContactName: suggestion.label });
      Global.setStateValue(this, 'contactid', suggestion.id);
    }
  }
  saveEvent = () => {
    this.props.parentComponent.saveEvent(this.state.schema);
  }
  componentDidUpdate() {
    if (this.state.isLoading && this.state.schema.length>0) {
      if (this.props.parentComponent.state.eventMode === 'edit' && this.state.rows.hasOwnProperty('id')) {        
        this.editEvent(this.state.rows);        
      } else if (this.props.parentComponent.state.eventMode === 'add') {
        this.addEvent(this.props.parentComponent.state.event); /* has contactid */
      }      
    }
  }
  componentDidMount() {    
    Global.getSchema(this, true);
  }

  setComponentState = (sState, vState) => {
    this.setState({ [sState]: vState });
  }

  render() {
    var formValues = this.state.schema;
    return (
      <Fragment>
        <Dialog
          open={this.state.dialogAddEditOpen}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth='md'
          fullWidth={true}
        >
          <DialogTitle id="alert-dialog-title">Event</DialogTitle>
          <DialogContent dividers>
            <DialogContentText id="alert-dialog-description"></DialogContentText>
            {this.state.isDialogDataLoading ?
              this.props.App.loadingCircular()
              :
              <div style={{ padding: 15 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    {formValues.filter(row => row.name === 'subject').map((row, index) => {
                      return (
                        <Fragment key={index}>
                          <TextField
                            variant="outlined"
                            autoFocus={true}
                            margin="dense"
                            error={row.error !== ''}
                            value={row.value}
                            name={row.name}
                            label={row.error ? row.error : row.label}
                            onChange={this.handleNewEventChange(row.name)}
                            required={row.required}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </Fragment>
                      );
                    })
                    }
                  </Grid>
                  <Grid item xs={12}>
                    {formValues.filter(row => row.name === 'description').map((row, index) => {
                      return (
                        <Fragment key={index}>
                          <TextField
                            multiline
                            rows={3}
                            margin="dense"
                            variant="outlined"
                            autoFocus={false}
                            error={row.error !== ''}
                            value={row.value}
                            name={row.name}
                            label={row.error ? row.error : row.label}
                            onChange={this.handleNewEventChange(row.name)}
                            required={row.required}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </Fragment>
                      );
                    })
                    }
                  </Grid>
                  <Grid item xs={4}>
                    {formValues.filter(row => row.name === 'date_start').map((row, index) => {
                      return (
                        <Fragment key={index}>
                          <MaterialUIPickers parentComponent={this} dateValue={row.value} />
                        </Fragment>
                      );
                    })
                    }
                  </Grid>
                  <Grid item xs={4}>
                    {formValues.filter(row => row.name === 'time_start').map((row, index) => {
                      return (
                        <Fragment key={index}>
                          <TextField
                            select
                            variant="outlined"
                            margin="dense"
                            autoFocus={false}
                            error={row.error !== ''}
                            value={row.value}
                            name={row.name}
                            label={row.error ? row.error : row.label}
                            onChange={this.handleNewEventChange(row.name)}
                            required={row.required}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          >
                            {row.selection.length > 0 && row.selection.map(option => (
                              <MenuItem key={option.key} value={option.key}>
                                {option.value}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Fragment>
                      );
                    })
                    }
                  </Grid>

                  <Grid item xs={4}>
                    {formValues.filter(row => row.name === 'activitytype').map((row, index) => {
                      return (
                        <Fragment key={index}>
                          <TextField
                            select
                            variant="outlined"
                            margin="dense"
                            autoFocus={false}
                            error={row.error !== ''}
                            value={row.value}
                            name={row.name}
                            label={row.error ? row.error : row.label}
                            onChange={this.handleNewEventChange(row.name)}
                            required={row.required}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          >
                            {row.selection.length > 0 && row.selection.map(option => (
                              <MenuItem key={option.key} value={option.key}>
                                {option.value}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Fragment>
                      );
                    })
                    }
                  </Grid>
                  <Grid item xs={6} style={{ zIndex: 200 }}>
                    {formValues.filter(row => row.name === 'contactid').map((row, index) => {
                      return (
                        <TextAutoSearch
                          key={index}
                          parentComponent={this.props.parentComponent}
                          updateRowObj={this.updateRowObj}
                          margin="dense"
                          textValue={this.state.eventContactName}
                          tabname='contact'
                          t={this} />
                      );
                    })
                    }
                  </Grid>
                  <Grid item xs={6}>
                    {formValues.filter(row => row.name === 'smownerid').map((row, index) => {
                      return (
                        <TextField
                          select
                          key={index}
                          variant="outlined"
                          margin="dense"
                          autoFocus={false}
                          error={row.error !== ''}
                          value={row.value}
                          name={row.name}
                          label={row.error ? row.error : row.label}
                          onChange={this.handleNewEventChange(row.name)}
                          required={row.required}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        >
                          {row.selection.length > 0 && row.selection.map(option => (
                            <MenuItem key={option.key} value={option.key}>
                              {option.value}
                            </MenuItem>
                          ))}
                        </TextField>
                      );
                    })
                    }
                  </Grid>

                </Grid>
              </div>
            }
          </DialogContent>
          <DialogActions>
            <Button onClick={this.saveEvent} variant='contained' color="primary" size="small" style={inlineStyles.button}>
              Save
            </Button>
            <Button onClick={this.closeEvent} variant='contained' color="secondary" size="small" style={inlineStyles.button}>
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </Fragment>
    );
  }
}